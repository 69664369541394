<template>
  <div>
    <project-add></project-add>
  </div>
</template>

<script>
import ProjectAdd from './ProjectAdd.vue';

export default {
  components: {
    ProjectAdd,
  },

  data() {
    return {};
  },
};
</script>
